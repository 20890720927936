<template>
  <v-bottom-sheet
    v-model="toggle"
    scrollable
  >
    <v-card 
      id="referrals" 
      class="sheet rounded-lg pt-4"
      dark
    >
      <div>
        <v-toolbar
          :elevation="toolbarElevation"
          color="transparent"
          class="page-header"
        >
          <v-expand-x-transition>
            <v-btn
              v-if="view.fullscreen"
              icon
              @click.stop="close"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-expand-x-transition>
          <v-spacer />
          <v-toolbar-title class="title text-body-1">
            Indicações
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-progress-circular
              v-if="view.loading"
              color="white"
              class="loading mt-4"
              indeterminate
              size="24"
              width="2"
            />
            <v-btn
              v-else
              @click.stop="toggleHelp(true, 'referrals')"
              icon
              class="mr-n3"
            >
              <v-icon v-text="icons.mdiHelpCircleOutline" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </div>

      <v-card-text
        v-if="!isEmpty"
        id="referrals-scroll"
        class="content pb-12 px-0"
        v-scroll:#referrals-scroll="onScroll"
        ref="content"
      >
        <div
          v-for="section in items"
          :key="section.title"
          class="section mb-4"
        >
          <v-subheader v-if="section.items.length>0">
            {{ section.title }}
          </v-subheader>

          <v-card 
            v-for="item in section.items"
            :key="item.name"
            elevation="1" 
            class="card mx-4"
            dark
          >
            <v-card-title class="name subtitle-1 text-truncate">
              {{ item.name }}
            </v-card-title>
            <span v-if="item.status=='ACT'||item.status=='REA'">
              <span class="progress-text subtitle-2 primary--text">
                {{ progressCycles(item.progress)+1 }}&ordm; mês
                <!-- quinzena<span v-if="item.progress<1||item.progress>=2">s</span> -->
              </span>
              <div class="progress-bars d-flex pb-6">
                <v-progress-linear
                  v-for="i in 4"
                  :key="i"
                  color="primary"
                  background-color="grey lighten-2"
                  stream
                  :buffer-value="currentCycle(item.progress,i)"
                  :value="completeCycle(item.progress,i)"
                  class="progress-bar-cycle"
                />
              </div>
            </span>
            <span 
              v-else
              class="progress-text subtitle-2 grey--text"
            >
              Aguardando vaga
            </span>
          </v-card>
        </div>
      </v-card-text>
      <div
        v-else
        class="empty-state d-flex flex-column justify-center flex-grow-1 text-center px-8 py-8" 
      >
        <img 
          class="empty-icon mx-auto"
          src="/img/empty-referrals.svg"
        >
        <h4 class="title text-h6 mt-12">
          Indique seus amigos
        </h4>
        <p class="text text-body-2 text--secondary my-6">
          Você pode ganhar um bônus de R$ 100 por cada parceiro indicado. Clique no botão abaixo e compartilhe seu link.
        </p>
      </div>

      <v-fab-transition>
        <v-btn
          class="btn add"
          color="primary"
          light
          absolute
          bottom
          right
          fab
          @click.stop="addReferral"
        >
          <v-icon class="icon">
            {{ icons.add }}
          </v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>

    <!-- TODO Make Component -->
    <v-dialog
      v-model="dialog.toggle"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h6">
          {{ dialog.headline }}
        </v-card-title>

        <v-card-text v-html="createKey(dialog.text).text" />
        
        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            @click="addReferral(false)"
          >
            {{ dialog.buttons.cancel.text }}
          </v-btn>

          <v-btn
            text
            @click="copyURL"
          >
            {{ dialog.buttons.ok.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-sheet>
</template>

<style type="text/css">

  .loading {
    opacity: .5;
  }

  #referrals .v-toolbar {
    z-index: 1;
  }

  #referrals .section .card {
    margin-bottom: 16px;
  }

  #referrals .section .card .name {
    display: block;
    max-width: 60%;
  }

  #referrals .section .card .progress-text {
    position: absolute;
    top: 16px;
    right: 16px;
    margin: 2px;
  }

  #referrals .empty-state .text {
    opacity: .75;
  }

  #referrals .progress-bars {
    padding: 0 16px 16px;
    margin-top: -4px;
  }

  #referrals .progress-bar-cycle {
    margin: 0 2px;
  }

  #referrals .btn.add {
    bottom: 1rem !important;
  }

  .referral-url {
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
</style>

<script>
  // Icons
  import { mdiHelpCircleOutline, mdiClose, mdiPlus } from '@mdi/js';

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import { Scroll } from 'vuetify/lib/directives';
  import _ from 'lodash';


  export default {
    name: 'Referrals',

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiClose,
        add: mdiPlus
      },
      toggle: false,
      offsetTop: 0,
      selectedTab: 'Rodando',
      dialog: {
        toggle: false,
        headline: 'Compartilhe o link',
        text: 'Para indicar alguém, copie o link abaixo e envie por mensagem para seu indicado.<br/><br/><a class="referral-url body-2" target="_blank" href="{{key}}">{{key}}</a>',
        buttons: {
          ok: {
            text: 'Copiar'
          },
          cancel: {
            text: 'Voltar'
          }
        }
      },
    }),

    computed: {
      views: sync('app/views'),
      view: sync('app/views@referrals'),
      items: sync('referrals/items'),
      help: get('help/sections'),
      user: get('user'),
      email: get('user/profile@email'),
      toast: sync('app/toast'),

      isEmpty () {
        return _.filter(this.items, (section) => {
          return section.items.length > 0;
        }).length == 0;
      },

      toolbarElevation () {
        return this.offsetTop >= 16 ? 2 : 0;
      },

      referralURL () {
        return 'https://mobees.com.br/dirija?email-indicacao='+this.email;
      },

      installed () {
        return typeof mobees != 'undefined';
      },
    },

    watch: {
      $route (route) {
        const toggle = _.has(route.query, 'subview') && route.query.subview=='referrals';
        this.toggle = toggle;
        if (toggle) {
          this.getData();
        }
      }
    },

    methods: {
      ...services,

      close () {
        this.$router.back();
      },

      onScroll (e) {
        this.offsetTop = e.target.scrollTop;
        if (!this.view.fullscreen&&this.offsetTop>5) {
          this.toggleFullscreen(true);
          this.$vuetify.goTo(0, {
            container: this.$refs.content,
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic'
          });
        }
      },

      createKey (text) {
        const key = this.referralURL;
        return {
          text: typeof text != 'undefined' ? text.replace(/{{key}}/g, key) : '',
          key: key
        };
      },

      copyURL () {
        // this.copyStringToClipboard(this.createKey().key);
        if (this.installed) {
          cordova.plugins.clipboard.copy(this.referralURL);
          this.onCopyURL();
        }else{
          navigator.clipboard.writeText(this.referralURL).then(this.onCopyURL, function(err) {
            console.error('Async: Could not copy text: ', err);
          });
        }
      },
        
      onCopyURL () {
        this.dialog.buttons.ok.text = 'Copiado!'
        this.toggleToast(
          true,
          'Link copiado! Compartilhe o link nas suas redes sociais 😉',
          5000,
          false
        );
      },

      progressCycles (p) {
        return Math.floor(p);
      },

      currentCycle (v, i) {
        return (v>i-1&&v<i) ? 0 : 100;
      },
      completeCycle (v, i) {
        return (v<i) ? 0 : 100;
      },

      addReferral (b){
        // move from state to prop sync
        this.dialog.toggle = b;
      },

      getData () {
        this.view.loading = true;

        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        
        this.$api
          .get('/getreferrals/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getReferrals => ',response);
            if(response.data.retorno==200){
              const data = JSON.parse(response.data.list);
              const progress = data.shift();
              this.items.operating.items = [];
              this.items.waiting.items = [];
              for (let item in data) {
                if (data[item].status=='ACT'||data[item].status=='REA') {
                  this.items.operating.items.push({
                    name: data[item].name,
                    status: data[item].status,
                    progress: data[item].progress,
                  });
                }else{
                  this.items.waiting.items.push({
                    name: data[item].name,
                    status: data[item].status,
                    progress: data[item].progress,
                  });
                }
              }
              console.log(this.items.operating.items, this.items.waiting.items);

            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.view.loading = false;
          });
      },
    },
    directives: {
      Scroll
    }
  }
</script>
